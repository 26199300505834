<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>재해예방조치</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="padding-left: 195px; margin-top: 20px; margin-bottom: 0px">
				<form action="#" @submit.prevent="search" @keyup.enter.prevent="search" search>
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>{{ detailsFieldMap.siteId }}</label>
								<!-- <div class="last" id="siteIdSelect2"> -->
								<div class="last">
									<select2 v-model="searchVM.siteId" :disabled="isSiteDisabled">
										<option value="">전체</option>

										<option v-for="item in searchOptions.siteOptions" :key="item.siteId" :value="item.siteId">
											{{ item.siteNm }}
										</option>
									</select2>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>{{ detailsFieldMap.companyId }}</label>
								<div class="last" style="width: 180px !important">
									<select2 v-model="searchVM.actnCompanyId">
										<option value="">전체</option>

										<option v-for="item in searchOptions.companyOptions" :key="item.companyId" :value="item.companyId">
											{{ item.companyNm }}
										</option>
									</select2>
								</div>
							</div>
						</div>
						<div class="col-md-4" style="width: 450px; margin-left: -27px">
							<div class="form-group">
								<label style="padding-right: 7px">{{ detailsFieldMap.accdtPrvtnPgssCd }}</label>
								<div class="checkbox" v-for="item in searchOptions.accdtPrvtnPgssCdOptions" :key="item.text">
									<label style="padding-left: 1.5em; padding-right: 1em">
										<input type="checkbox" v-model="searchVM.accdtPrvtnPgssCds" :value="item.value" />
										{{ item.text }}
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" period>
								<label>{{ detailsFieldMap.period }}</label>
								<select2 v-model="searchVM.period" :options="searchOptions.periodOptions" style="width: 20%" :change="getValue(searchVM.period)">
									<option value="">전체</option>
								</select2>
								<input type="date" class="form-control" v-model="searchVM.periodStrtDt" :disabled="isPeriodDisabled" />
								&nbsp;~&nbsp;
								<input
									type="date"
									class="form-control"
									v-model="searchVM.periodEndDt"
									:disabled="isPeriodDisabled"
									:min="searchVM.periodStrtDt"
									:max="maxDay"
								/>
							</div>
						</div>
						<div class="col-md-4 row-mg-left">
							<div class="form-group">
								<label style="margin: 0">{{ detailsFieldMap.cont }}</label>
								<div class="last">
									<input
										type="text"
										style="display: inline-block; width: 340px"
										class="form-control"
										:placeholder="detailsFieldMap.cont"
										v-model="searchVM.cont"
									/>
								</div>
							</div>
						</div>
						<button type="submit" class="btn btn-labeled bg-primary" style="float: right">
							<b><i class="icon-search4"></i></b>
							검색
						</button>
					</div>
				</form>
			</div>
		</div>
		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>
		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title"><b>요청</b></h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body">
					<div class="row" style="width: 100%; margin: auto">
						<fieldset>
							<div class="col-md-6">
								<!-- 재해유형 -->
								<jarvis-field :label="detailsFieldMap.accdtTypeCd" :required="isCreateMode">
									<select2
										v-model="detailsItem.accdtTypeCd"
										data-vv-name="detailsItem.accdtTypeCd"
										:disabled="isDisabledHistory || isDisabledComplete"
									>
										<option value="">선택</option>
										<option v-for="item in searchOptions.cdOptions" :key="item.cd" :value="item.cd">
											{{ item.cdNm }}
										</option>
									</select2>
								</jarvis-field>
								<!-- 조치기한 -->
								<jarvis-field
									:label="detailsFieldMap.actnDeadlineDt"
									:value="detailsItem.actnDeadlineDt | toDisplayDate"
									:disabled="true"
								></jarvis-field>
								<!-- 요청자 -->
								<jarvis-field
									:label="detailsFieldMap.reqUserId"
									disabled="isDisabledHistory"
									field="detailsItem.reqUserId"
									:value="detailsItem.reqUserNm"
									v-show="isEditMode"
								></jarvis-field>
							</div>
							<div class="col-md-6">
								<!-- 대상물 -->
								<div>
									<jarvis-field
										:label="detailsFieldMap.objectId"
										field="detailsItem.objectId"
										v-model="detailsItem.objectId"
										data-vv-name="detailsItem.objectId"
										:disabled="true"
									></jarvis-field>
								</div>
								<!-- 조치자 -->
								<jarvis-field :label="detailsFieldMap.actnUserId">
									<select2 v-model="detailsItem.actnCompanyId" :disabled="true">
										<option value="">선택</option>
										<option v-for="item in searchOptions.companyOptions" :key="item.companyId" :value="item.companyId">
											{{ item.companyNm }}
										</option>
									</select2>
								</jarvis-field>
								<!-- 요청일자 -->
								<jarvis-field
									:label="detailsFieldMap.reqDtm"
									:disabled="true"
									v-if="isEditMode"
									:value="detailsItem.reqDtm | toDisplayDateTime"
								></jarvis-field>
							</div>
							<div img>
								<img :src="reqImageSrc" style="max-width: 100%; height: 310px; object-fit: contain; padding-bottom: 20px" />
							</div>
							<div style="margin-bottom: 10px" v-show="isCreateMode" align="center">
								<input type="file" class="hidden" v-on:change="handleFileUpload()" ref="reqFile" />
								<button class="btn btn-primary" style="width: 100%" @click="clickFileBtn">파일첨부</button>
							</div>
							<!-- 요청내용 -->
							<textarea
								class="form-control"
								style="resize: none; width: 100%; height: 150px; margin: auto"
								v-model="detailsItem.reqCont"
								placeholder="요청 내용"
								:disabled="isDisabledHistory || isDisabledComplete"
							></textarea>
						</fieldset>
					</div>
				</div>
			</div>
			<div class="panel panel-flat" v-show="isEditMode">
				<div class="panel-heading">
					<h6 class="panel-title"><b>조치</b></h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body">
					<div class="row" style="width: 100%; margin: auto">
						<fieldset>
							<div>
								<!-- 조치일자 -->
								<div style="width: 100%; height: 36px; margin: auto">
									<button
										class="btn bg-primary pull-right"
										@click.prevent="showPopupTarget"
										data-toggle="modal"
										data-target="#showPopupTarget"
										:disabled="!isDisabledComplete"
									>
										모범사례등록
									</button>
								</div>
								<!-- 조치자 -->
								<div style="margin-top: 20px">
									<jarvis-field
										:label="detailsFieldMap.actnDtm"
										disabled="true"
										v-if="isEditMode"
										:value="detailsItem.actnDtm | toDisplayDateTime"
										style="display: inline-block"
									></jarvis-field>
									<jarvis-field
										:label="detailsFieldMap.actnUserId"
										:disabled="true"
										required="true"
										v-model="detailsItem.actnCompanyUserId"
										style="margin-top: -6px"
									></jarvis-field>
								</div>
							</div>
							<div img>
								<img :src="resImageSrc" style="max-width: 100%; height: 310px; object-fit: contain; padding-bottom: 20px" />
							</div>
							<textarea
								class="form-control"
								style="resize: none; width: 100%; height: 150px; margin: auto"
								v-model="detailsItem.actnCont"
								placeholder="조치 내용"
								:disabled="true"
							></textarea>
						</fieldset>
					</div>
				</div>
			</div>
			<div class="btn-wrapper" style="width: 100%; clear: both">
				<div v-show="isEditMode">
					<button class="btn bg-primary" @click.stop="reRequest" :disabled="isDisabledHistory || isDisabledReq || isDisabledComplete">재요청</button>
					<button class="btn bg-success" @click.stop="completeAction" :disabled="isDisabledHistory || isDisabledReq || isDisabledComplete">
						조치완료
					</button>
					<button class="btn bg-danger pull-right" @click.stop="deleteData">삭제</button>
					<button class="btn btn-default pull-right" @click.stop="closeDetails">닫기</button>
				</div>
				<div v-show="isCreateMode">
					<button class="btn bg-primary pull-right" @click="createData">요청하기</button>
					<button class="btn btn-default pull-right" @click="closeDetails">닫기</button>
				</div>
			</div>
		</form>
		<div v-show="isEditMode">
			<label style="font-size: 1.4em; margin-top: 15px; width: 100%">History</label>
			<table class="table">
				<thead>
					<tr>
						<th>일자</th>
						<th>요청내용</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in historyItem" :key="index" v-on:click="selectHistoryRowChanged(item, index)">
						<td>{{ item.reqDtm }}</td>
						<td>{{ item.reqCont }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div id="showPopupTarget" class="modal fade">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal">&times;</button>
						<h5 class="modal-title">조치대상 검색</h5>
					</div>
					<div class="modal-body">
						<div class="table-wrapper" ref="tableWrapperTarget">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>조치대상ID</th>
										<th>조치대상명</th>
										<th>조치대상 영문명</th>
										<th>메모</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="row in tableDataPopup" :key="row.targetId" v-on:click="clickPopupRowTarget(row.targetId, row.targetNm)">
										<td>{{ row.targetId }}</td>
										<td>{{ row.targetNm }}</td>
										<td>{{ row.targetEngNm }}</td>
										<td>{{ row.memo }}</td>
									</tr>
									<tr empty>
										<td colspan="4">등록된 조치대상이 없습니다</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import JarvidField from '@/components/JarvisField';
import extend from 'extend';
import moment from 'moment';
import _ from 'lodash';
import apiIndex from '../../api/index';
const commUrl = apiIndex.comm;
const accidentPreUrl = apiIndex.accidentPrevent;

let axiosExtention;

export default {
	components: {
		KendoGrid,
		select2: Select2,
		'jarvis-field': JarvidField,
	},
	data: () => ({
		pageParam: null,
		apiUrl: {
			excelApi: '/system/commcodemng/excel',
			pageListApi: accidentPreUrl.inqAccidentPrevent,
			detailsApi: '/system/commcodemng/api/details',
		},
		searchVM: {},
		// 상세 select options
		detailsOptions: {},
		// 검색 select options
		searchOptions: {},
		// 적용된 검색조건
		searchState: {},
		gridColumns: [
			{ field: 'accdtPrvtnActnReqSeq', title: '번호', align: 'right', sortable: false },
			{ field: 'siteNm', title: '현장명' },
			{
				field: 'reqDtm',
				title: '요청일자',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
			{ field: 'reqUserNm', title: '요청자' },
			{
				field: 'reqCont',
				title: '요청내용',
			},
			{
				field: 'accdtPrvtnPgssCd',
				title: '진행상태',
				align: 'center',
				dataFormat: 'optionMap',
			},
			{ field: 'actnCompanyNm', title: '업체명' },
			{
				field: 'actnDtm',
				title: '조치일자',
			},
			{ field: 'actnUserNm', title: '조치자' },
			{
				field: 'actnCont',
				title: '조치내용',
			},
			{
				field: 'decimalDay',
				title: 'D-day',
				align: 'right',
				dataFormat: 'dDayCheck',
				width: '5%',
			},
		],
		detailsFieldMap: {
			title: '번호',
			siteId: '현장',
			companyId: '업체',
			objectId: '대상물',
			targetId: '조치대상',
			accdtPrvtnPgssCd: '진행상태',
			reqUserId: '요청자',
			reqDtm: '요청일자',
			actnDeadlineDt: '조치기한일자',
			accdtTypeCd: '재해유형',
			cont: '내용',
			actnDtm: '조치일자',
			actnUserId: '조치자',
			dDay: 'D-day',
			period: '기간',
			reqCont: '요청내용',
			actnCont: '조치내용',
		},
		actnCompanyUserId: '',
		detailsValidationRule: {
			common: {
				// 공통
				mstCode: 'required|max:45',
				commonCode: 'required|max:45',
				commonCodeName: 'required|max:45',
				reservedValue1: 'max:200',
				displayOrder: 'required',
				closed: 'required',
				memo: 'max:4000',
			},
			edit: {
				// 수정
			},
			create: {
				// 생성
			},
		},
		// 상세편집 원본
		detailsItemOriginal: {},
		// 상세편집 수정본
		detailsItem: {},
		historyItem: {},
		reqImageSrc: '/images/noimage.png',
		resImageSrc: '/images/noimage.png',
		evlu: ['사진 등록 필요'],
		isSiteDisabled: false,
		isPeriodDisabled: null,
		period: '',
		isDisabledHistory: false,
		isDisabledReq: false,
		isDisabledRsl: false,
		isDisabledComplete: false,
		tableDataPopup: [],
		//periodEndDt의 지정 가능한 최대 일자
		maxDay: '',
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('accidentPreventPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}
		//검색VM
		this.searchVM = {
			siteId: this.pageParam.loginUserSiteId,
			actnCompanyId: '',
			accdtPrvtnPgssCds: this.pageParam.accdtPrvtnPgssCds,
			period: this.pageParam.period,
			periodStrtDt: moment().add(-6, 'day').format('YYYY-MM-DD'),
			periodEndDt: moment().format('YYYY-MM-DD'),
			cont: '',
		};

		const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));

		//상세 select options
		this.detailsOptions = {
			siteOptions: this.pageParam.siteOptions,
			companyOptions: this.pageParam.companyOptions,
			cdOptions: this.pageParam.cdOptions,
			accdtPrvtnPgssCdOptions: commonCodeList.filter(item => item.grpCd == 'accdt_prvtn_pgss_cd'),
			periodOptions: commonCodeList.filter(item => item.grpCd == 'period'),
		};

		this.searchOptions = {
			siteOptions: this.detailsOptions.siteOptions,
			companyOptions: this.detailsOptions.companyOptions,
			cdOptions: this.detailsOptions.cdOptions,
			accdtPrvtnPgssCdOptions: this.detailsOptions.accdtPrvtnPgssCdOptions,
			periodOptions: this.detailsOptions.periodOptions,
		};

		this.gridColumns.filter(item => item.field == 'accdtPrvtnPgssCd')[0].mapData = this.detailsOptions.accdtPrvtnPgssCdOptions;

		axiosExtention = this.$jarvisExtention.axiosExtention;
	},
	mounted() {
		this.search();
	},
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			return {
				detailsItem: this.isEditMode ? this.detailsValidationRule.edit : this.detailsValidationRule.create,
			};
		},
		isDisabled() {
			return false;
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); // Validation상태 초기화
			// details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal))
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
		},
		'searchVM.siteId': {
			handler(value, oldValue) {
				if (oldValue !== null) {
					this.$axios
						.post(commUrl.inqCompanyFindBySiteId, { siteId: this.searchVM.siteId })
						.then(
							function (r) {
								this.searchOptions.companyOptions = r.data;
								this.searchVM.actnCompanyId = '';
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		'searchVM.periodStrtDt': {
			handler(value) {
				this.maxDay = moment(value).add(process.env.VUE_APP_MAX_MONTH, 'month').format('YYYY-MM-DD');
			},
		},
		period() {
			if (this.period === 'today' || this.period === 'delay') {
				this.isPeriodDisabled = true;
				// 값은 사용되지 않는다, 보여주기용
				this.searchVM.periodStrtDt = moment().format('YYYY-MM-DD');
				this.searchVM.periodEndDt = moment().format('YYYY-MM-DD');
				this.searchVM.accdtPrvtnPgssCds = ['01', '02', '03'];
			} else {
				this.isPeriodDisabled = false;
				this.searchVM.accdtPrvtnPgssCds = ['01', '02', '03', '04'];
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		// data pannel hidden toggle
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: accidentPreUrl.exlAccidentPrevent,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = '재해예방조치_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		selectOne(selectedRowItem, isHistory) {
			this.$axios
				.post(accidentPreUrl.inqOneAccidentPrevent, {
					accdtPrvtnActnReqSeq: selectedRowItem.accdtPrvtnActnReqSeq,
					reReqSeq: selectedRowItem.reReqSeq,
					isHistory: isHistory,
				})
				.then(
					function (response) {
						this.evlu = response.data.evlu;
						// 회사명과 조치자 연결하여 뿌리기
						this.pageParam.companyOptions.forEach(function (e) {
							if (response.data.actnCompanyId === e.companyId) {
								response.data.actnCompanyUserId = e.companyNm;
								// null이 아니고 비어있지 않으면 출력
								if (response.data.actnUserId !== null && response.data.actnUserId.trim() != '') {
									response.data.actnCompanyUserId += ' - ' + response.data.actnUserNm;
								}
							}
						});

						this.reqImageSrc = 'data:image/jpg;base64,' + response.data.reqImageSrc;
						this.resImageSrc = 'data:image/jpg;base64,' + response.data.resImageSrc;

						this.detailsItemOriginal = extend(true /*deep*/, {}, response.data);
						this.detailsItem = extend(true /*deep*/, {}, response.data);

						// 기존 Disabled들 초기화
						this.isDisabledHistory = false;
						this.isDisabledReq = false;
						this.isDisabledRsl = false;
						this.isDisabledComplete = false;
						if (isHistory) {
							//history면 전체 비활성화
							this.isDisabledHistory = true;
						} else {
							switch (this.detailsItem.accdtPrvtnPgssCd) {
								case '01': // 요청
								case '03': // 재요청
									this.isDisabledReq = true;
									break;
								case '02': // 검토
									this.isDisabledRsl = true;
									break;
								case '04': // 완료
									this.isDisabledComplete = true;
									break;
							}
						}
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				this.selectOne(selectedRowItem, false);
				this.histroyLoad(selectedRowItem);
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//히스토리 목록 불러오기
		histroyLoad(selectedRowItem) {
			this.$axios
				.post(accidentPreUrl.inqAccidentPreventFindByAccdtPrvtnActnReqSeq, {
					accdtPrvtnActnReqSeq: selectedRowItem.accdtPrvtnActnReqSeq,
				})
				.then(
					function (response) {
						this.historyItem = extend(true /*deep*/, [], response.data);
						this.historyItem.forEach(e => {
							// 날짜 포맷 수정
							e.reqDtm = moment(e.reqDtm).format('YYYY-MM-DD HH:mm:ss');
						});
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		//히스토리 row 선택처리
		selectHistoryRowChanged(selectedRowItem, index) {
			if (index === 0) {
				// 가장 최근 데이터는 disabled 하지 않는다.
				this.selectOne(selectedRowItem, false);
			} else {
				this.selectOne(selectedRowItem, true);
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
			this.histroyItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.reqImageSrc = '/images/noimage.png';
			this.detailsItemOriginal = {};
			this.detailsItem = {
				accdtTypeCd: '',
				actnDeadlineDt: '',
				reqUserId: '',
				objectId: '',
				actnUserId: '',
				imgFilePath: '',
				imgFileNm: '',
				reqDtm: '',
				targetId: '',
				aiAccidentPreventResList: [],
				images: [],
			};
		},
		/*
		 * 파일 업로드 컨트롤
		 */
		// 파일첨부 버튼을 누르면 type=file element를 클릭하도록 우회 처리
		clickFileBtn(e) {
			e.preventDefault();
			// 대상물과 조치대상이 선택되어 있어야 동작
			this.$refs.reqFile.click();
		},
		// 파일이 첨부되었을 때 처리
		handleFileUpload() {
			this.uploadTemp(0, '');
		},
		// temp 파일 분할 업로드
		uploadTemp(offset, fileName) {
			var file = this.$refs.reqFile.files[0];
			if (offset >= file.size) {
				// 업로드 완료 후처리
				this.$axios.post(accidentPreUrl.aiAccidentPrevent + fileName).then(
					function (r) {
						this.$axios.post(accidentPreUrl.imgOneAccidentPrevent + fileName + 'bbox', {}).then(response => {
							this.reqImageSrc = 'data:image/jpg;base64,' + response.data.imgVO.imgByte;
						});
						this.detailsItem.imgSaveFileNm = fileName;
						this.detailsItem.imgOriFileNm = file.name;
						this.detailsItem.aiAccidentPreventResList = r.data;
						this.evlu = r.data;
					}.bind(this),
				);
			} else {
				var size = Math.min(offset + 1000000, file.size);
				var formData = new FormData();
				formData.append('objectId', this.detailsItem.objectId);
				formData.append('fileName', fileName);
				formData.append('uploadFile', file.slice(offset, size));
				this.$axios
					.post(accidentPreUrl.uplAccidentPrevent, formData, {
						headers: { 'Content-Type': 'multipart/form-data' },
					})
					.then(
						function (r) {
							this.uploadTemp(size, r.data);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		//신규저장
		createData(e) {
			e.preventDefault();
			this.$axios.post(accidentPreUrl.insAccidentPrevent, this.detailsItem).then(
				function () {
					this.closeDetails();
					this.reqImageSrc = '/images/noimage.png';
					this.searchState = extend(true /*deep*/, {}, this.searchVM);
					this.loadGrid();
					window.scrollTo({ top: 50, behavior: 'smooth' });
				}.bind(this),
			);
		},
		reRequest() {
			this.detailsItem.accdtPrvtnPgssCd = '03';
			this.$axios
				.post(accidentPreUrl.reInsAccidentPrevent, this.detailsItem)
				.then(
					function () {
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		completeAction() {
			this.detailsItem.accdtPrvtnPgssCd = '04';
			this.$axios
				.post(accidentPreUrl.mUpdAccidentPrevent, this.detailsItem)
				.then(
					function () {
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			this.$axios
				.post(accidentPreUrl.delAccidentPrevent, {
					accdtPrvtnActnReqSeq: this.detailsItem.accdtPrvtnActnReqSeq,
				})
				.then(
					function () {
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		// 검색조건의 select box 값이 변경되면
		getValue(value) {
			this.period = value;
		},
		// 조치대상 list 호출
		showPopupTarget() {
			this.getPopupTargetList();
		},
		// 조치대상 list 불러오기
		getPopupTargetList() {
			this.$axios
				.post(accidentPreUrl.inqPopupAccidentPreventTargetByObjectId, { objectId: this.detailsItem.objectId })
				.then(
					function (response) {
						this.tableDataPopup = response.data;
						this.$refs.tableWrapperTarget.scrollTo({ top: 0, behavior: 'smooth' });
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		// 팝업 리스트의 타겟 클릭 시 동작
		clickPopupRowTarget(targetId) {
			if (!confirm('대상물: ' + this.detailsItem.objectId + ' 조치대상: ' + targetId + ' 등록하시겠습니까?')) {
				return;
			}
			this.detailsItem.targetId = targetId;
			this.detailsItem.images = [];
			this.detailsItem.images.push({
				img: this.detailsItem.resImgSaveFileNm,
				imgOriNm: this.detailsItem.resImgOriFileNm,
				objectId: this.detailsItem.objectId,
				targetId: this.detailsItem.targetId,
			});
			this.$axios
				.post(accidentPreUrl.accidentPreventInsExemplaryCase, this.detailsItem)
				.then(
					function () {
						alert('저장되었습니다.');
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			// 창 숨기기
			$('#showPopupTarget').modal('hide');
		},
	},
};
</script>

<style>
.checkbox {
	display: inline-block !important;
	margin-left: 0.7em;
}
.row .form-group .last {
	display: inline-block;
	width: 65% !important;
	margin-left: 15px;
}
.row:last-child .form-group > label {
	margin-right: 25px;
}
.row [period] {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.row [period] [type='date'] {
	width: 180px !important;
}
.row [period] > label {
	margin-top: 10px;
	margin-right: 15px !important;
}
.row [period] > :nth-child(3) {
	width: 98px !important;
	margin-right: 15px;
}
#detailsForm .panel-flat {
	width: calc(50% - 10px);
	display: inline-block;
}
#detailsForm .panel-flat:nth-child(2) {
	float: right;
}
#detailsForm .btn-wrapper .btn {
	margin-right: 5px;
}
#detailsForm .form-group > :last-child {
	width: 55% !important;
	margin-left: 10px;
}
#detailsForm .row:last-child .form-group > label {
	margin-right: 10px;
	width: 110px;
}
.table th {
	background: #eee;
	font-weight: bold;
	font-size: 1em;
}
.table th:first-child {
	width: 160px;
	text-align: center;
}
.table td {
	font-size: 1em;
}
.table td:first-child {
	text-align: center;
}
.table tbody tr:hover td {
	background: none repeat scroll 0 0 #bcc3d0;
	color: #000000;
}
[img] {
	clear: both;
	text-align: center;
	width: 100%;
}
.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}
</style>
